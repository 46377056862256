import {
  GET_HOSPITALS_LOADING,
  GET_HOSPITALS_SUCCESS,
  GET_HOSPITALS_ERROR,
  GET_HOSPITALS_ROWS_PER_PAGE,
  GET_HOSPITALS_PAGE_NUMBER,
  UPDATE_HOSPITAL,
  DELETE_HOSPITAL,
  ADD_HOSPITAL
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  hospitalsList: [],
  hospitalsMap: {},
  singleHospitalId: "",
  singleHospitalMap: {},
  loading: false,
  singleHospitalLoading: false,
  error: "",
  rowsPerPage: 10,
  page: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_HOSPITALS_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case GET_HOSPITALS_SUCCESS:
      return {
        ...state,
        loading: false,
        hospitalsList: action.payload.hospitalsList,
        hospitalsMap: action.payload.hospitalsMap
      };

    case GET_HOSPITALS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case GET_HOSPITALS_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload
      };

    case GET_HOSPITALS_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload
      };

    case UPDATE_HOSPITAL:
      let tempList = state.hospitalsList.map(elem => {
        return elem.uid === action.payload.uid ? action.payload : elem;
      });
      return {
        ...state,
        hospitalsList: [...tempList]
      };

    case ADD_HOSPITAL:
      return {
        ...state,
        hospitalsList: [...state.hospitalsList, action.payload]
      };

    case DELETE_HOSPITAL:
      let tempListDelete = state.hospitalsList.filter(elem => {
        return elem.uid !== action.payload.uid;
      });
      return {
        ...state,
        hospitalsList: [...tempListDelete]
      };

    default:
      return state;
  }
};
