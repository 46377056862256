import { firestore, auth } from "../../firebase/firebase";
import firebase from "firebase";
/**
 *
 * Class handle all the firestore queries
 *
 */
export default class FirestoreService {
  // ***************************************************
  // Get data from the database.
  // ***************************************************

  /**
   *
   * Get the users for the Users screen.
   *
   */
  static async getUsers() {
    let ref = firestore.collection("users").orderBy("timestamp", "desc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get single user for the small card info.
   *
   * @param {String} userId UserId for which the document is to be retrieved.
   *
   */
  static async getSingleUser(userId) {
    let ref = firestore.collection("users").where("uid", "==", userId);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data to send in object format
        let obj = {
          success: true,
          data: {},
        };

        snapshot.forEach((doc) => {
          obj.data = doc.data();
        });

        return obj;
      })
      .catch((e) => {
        return { error: true, message: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the doctors for the Doctors screen.
   *
   */
  static async getDoctors() {
    let ref = firestore.collection("doctors").orderBy("timestamp", "desc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get single doctor for the small card info.
   *
   * @param {String} doctorId DoctorId for which the document is to be retrieved.
   *
   */
  static async getSingleDoctor(doctorId) {
    let ref = firestore.collection("doctors").where("uid", "==", doctorId);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data to send in object format
        let obj = {
          success: true,
          data: {},
        };

        snapshot.forEach((doc) => {
          obj.data = doc.data();
        });

        return obj;
      })
      .catch((e) => {
        return { error: true, message: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the hospitals for the Hospitals screen.
   *
   */
  static async getHospitals() {
    let ref = firestore.collection("hospitals").orderBy("timestamp", "desc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the hospitals for the Hospitals screen.
   *
   */
  static async getBlogs() {
    let ref = firestore.collection("blogs").orderBy("timestamp", "desc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Gets all the notifications for the admin to broadcast to the users
   *
   */
  static async getNotifications() {
    let ref = firestore
      .collection("admin")
      .doc("notifications")
      .collection("notifications")
      .orderBy("timestamp", "desc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the appointments for the Appointments screen.
   *
   */
  static async getAppointments() {
    let ref = firestore.collection("appointments").orderBy("createdAt", "desc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get single appointment.
   *
   * @param {String} appointmentId ID for which the document is to be retrieved.
   *
   */
  static async getSingleAppointment(appointmentId) {
    let ref = firestore.collection("appointments").doc(appointmentId);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data to send in object format
        let obj = {
          success: true,
          data: {},
        };

        obj.data = snapshot.data();

        return obj;
      })
      .catch((e) => {
        return { error: true, message: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the appointments for the Appointments screen.
   *
   */
  static async getPayments() {
    let ref = firestore.collection("payments").orderBy("timestamp", "desc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the list of illness from the database.
   *
   */
  static async getIllnessList() {
    let ref = firestore.collection("admin").doc("illnessList");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        obj.data = snapshot.data()["illnessList"];

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Add illness values to the list of illness
   * @param {Array<String>} values an array of string to add to the list.
   *
   */
  static async addIllness(values) {
    let ref = firestore.collection("admin").doc("illnessList");
    let result = await ref.update({
      illnessList: firebase.firestore.FieldValue.arrayUnion(...values),
    }).then(_ => {
      console.log("Success");
      return { success: true };
    }).catch(e => {
      console.log("Failed with error ", e);
      return { error: true, errorText: e.toString() };
    });
    return result;
  }

  /**
   *
   * Delete illness value from the list of illness
   * @param {String} value a string to delete from the list.
   *
   */
  static async deleteIllness(values) {
    let ref = firestore.collection("admin").doc("illnessList");
    let result = await ref.update({
      illnessList: firebase.firestore.FieldValue.arrayRemove(...values),
    }).then(_ => {
      console.log("Success");
      return { success: true };
    }).catch(e => {
      console.log("Failed with error ", e);
      return { error: true, errorText: e.toString() };
    });
    return result;
  }

  /**
   * 
   * Checks if the email is allowed to go into the admin panel or not.
   * 
   */
  static async checkAdminUser(email) {
    const query = firestore.collection('admin').doc('admin').collection('users').where('email', '==', email);
    const result = await query.get().then(snapshot => {
      if (snapshot.docs.length > 0) {
        console.log('Data available');
        return { success: true, payload: snapshot.docs[0].data()};
      } else {
        console.log('Data not available');
        return { error: true, message: "Entry not allowed"};
      }
    }).catch(e => {
      console.log(e);
      return {error: true, message: e.toString()};
    });
    return result;
  }

  // ***************************************************
  // Functions to adds data to the database
  // ***************************************************

  /**
   * Creates a notification to broadcast to the users.
   *
   * @param {Map<String, String>} data document data to set
   *
   */
  static async createNotification(data) {
    let ref = firestore
      .collection("admin")
      .doc("notifications")
      .collection("notifications")
      .doc();
    data.uid = ref.id;
    data.timestamp = new Date().getTime().toString();
    let result = await ref
      .set(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: e.toString() };
      });

    return result;
  }

  static async deleteNotification(uid) {
    let ref = firestore
      .collection("admin")
      .doc("notifications")
      .collection("notifications")
      .doc(uid);
    let result = await ref
      .delete()
      .then(() => {
        return { success: true };
      })
      .catch((e) => {
        return { error: e.toString() };
      });

    return result;
  }

  /**
   *
   * Add new hospital to the database
   *
   * @param {Map<String, String>} data Map Object which contains the data for the document.
   *
   */
  static async addNewHospital(data) {
    let ref = firestore.collection("hospitals").doc();
    data.uid = ref.id;
    data.timestamp = new Date().getTime().toString();
    let result = await ref
      .set(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: e.toString() };
      });

    return result;
  }

  static async addNewBlog(data) {
    let ref = firestore.collection("blogs").doc();
    data.uid = ref.id;
    data.timestamp = new Date().getTime().toString();
    let result = await ref
      .set(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }

  // ***************************************************
  // Functions to update database
  // ***************************************************

  static async updatePassword(newPassword) {
    let user = auth.currentUser;
    let result = await user
      .updatePassword(newPassword)
      .then(function() {
        // Update successful.
        return { success: true };
      })
      .catch(function(error) {
        // An error happened.
        return { error: true, errorText: error.toString() };
      });

    return result;
  }

  // TODO: Update the admin values as well and TEST IT
  static async updateEmail(newEmail) {
    let user = auth.currentUser;
    const oldEmail = user.email;

    let result = await user
      .updateEmail(newEmail)
      .then(async (_) => {

        console.log('Updated auth');

        // update email in the admin document
        const query = firestore
          .collection("admin")
          .doc("admin")
          .collection("users")
          .where("email", "==", oldEmail);
        
        let r = await query
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length > 0) {
              console.log("updating doc data");

              snapshot.forEach(async (doc) => {
                await doc.ref.update({ email: newEmail }).then(_ => {
                  console.log('Updated email in DB');
                }).catch(e => {
                  console.log('Update email failed in DB', e);
                });
              })

            } else {
              console.log("Data not available");
            }
          })
          .catch((e) => {
            console.log(e);
          });

          
          localStorage.setItem(
          "email",
          newEmail
        );
        // Update successful.
        return { success: true };
      })
      .catch((error) => {
        // An error happened.
        return { error: true, errorText: error.toString() };
      });

    return result;
  }

  static async updateHospital(hospitalObject) {
    const ref = firestore.collection("hospitals").doc(hospitalObject.uid);
    let result = await ref
      .update(hospitalObject)
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }

  static async updateBlog(blogObject) {
    const ref = firestore.collection("blogs").doc(blogObject.uid);
    let result = await ref
      .update(blogObject)
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }

  // ****************************************************
  // Delete functions
  // ****************************************************

  static async deleteHospital(hospitalObject) {
    const ref = firestore.collection("hospitals").doc(hospitalObject.uid);
    let result = await ref
      .delete()
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }

  static async deleteBlog(blogObject) {
    const ref = firestore.collection("blogs").doc(blogObject.uid);
    let result = await ref
      .delete()
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }
}

/**
 *
 * Class that is just a mock test for users to see the panel
 * but not actually maniputale the data.
 *
 */
class MockTest {
  static mockMessage =
    "This is a demo and you are not allowed to perform this action.";

  /**
   * Creates a notification to broadcast to the users.
   *
   * @param {Map<String, String>} data document data to set
   *
   */
  static async createNotification() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async deleteNotification() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async addNewHospital() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async addNewBlog() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  // ***************************************************
  // Functions to update database
  // ***************************************************

  static async updatePassword() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async updateEmail() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async updateHospital() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async updateBlog() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  // ****************************************************
  // Delete functions
  // ****************************************************

  static async deleteHospital() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async deleteBlog() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }
}
