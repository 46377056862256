import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_HOSPITALS } from "../constants/ActionTypes";
import { getHospitalsError, getHospitalsSuccess } from "../actions/Hospitals";
import FirestoreService from "../services/firestoreService";

function* getHospitalsHelper() {
  try {
    const result = yield call(FirestoreService.getHospitals);
    if (result.data !== undefined) {
      // It means there is  data to display
      // console.log(result.data)
      yield put(getHospitalsSuccess(result.data));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getHospitalsError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getHospitalsError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getHospitalsSaga() {
  yield takeEvery(GET_HOSPITALS, getHospitalsHelper);
}

export default function* rootSaga() {
  yield all([fork(getHospitalsSaga)]);
}
