import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import FirestoreService from "services/firestoreService";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from "actions/Auth";

const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      props.history.push("/");
    }
  }, [showMessage, authUser, props.history, dispatch]);

  const signIn = async () => {
    setLoading(true);

    if (email) {
      if (email.trim() != "") {
        // Check if id is in the admin/users collection
        let result = await FirestoreService.checkAdminUser(email);

        if (result.success !== undefined) {
          setLoading(false);
          dispatch(showAuthLoader());
          dispatch(userSignIn({ email, password }));
        } else {
          setLoading(false);
          NotificationManager.error(result.message ?? "Not allowed");
        }
      }
    } else {
      setLoading(false);
      NotificationManager.error("Email is not valid");
    }
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <Paper>
        <Box padding={4}>
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="appModule.signin" />
            </h1>
          </div>

          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  variant="outlined"
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={(event) => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  variant="outlined"
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button onClick={signIn} variant="contained" color="primary">
                    <IntlMessages id="appModule.signIn" />
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </Box>
      </Paper>

      {(loading || loader) && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      {showMessage && NotificationManager.error(alertMessage)}
      <NotificationContainer />
    </div>
  );
};

export default SignIn;
