import {
  GET_DOCTORS,
  GET_DOCTORS_ERROR,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_LOADING,
  GET_DOCTORS_ROWS_PER_PAGE,
  GET_DOCTORS_PAGE_NUMBER,
  ADD_DOCTOR,
  DELETE_DOCTOR,
} from "../constants/ActionTypes";

export const getDoctors = () => {
  return {
    type: GET_DOCTORS
  };
};

export const getDoctorsLoading = value => {
  return {
    type: GET_DOCTORS_LOADING,
    payload: value
  };
};

export const getDoctorsError = error => {
  return {
    type: GET_DOCTORS_ERROR,
    payload: error
  };
};

export const getDoctorsSuccess = (doctorsList, doctorsMap) => {
  return {
    type: GET_DOCTORS_SUCCESS,
    payload: {
      doctorsList,
      doctorsMap
    }
  };
};

export const setRowsPerPage = value => {
  return {
    type: GET_DOCTORS_ROWS_PER_PAGE,
    payload: value
  };
};

export const setPageNumber = value => {
  return {
    type: GET_DOCTORS_PAGE_NUMBER,
    payload: value
  };
};

export const addDoctor = doctorObject => {
  return {
    type: ADD_DOCTOR,
    payload: doctorObject
  };
};

export const deleteDoctor = doctorObject => {
  return {
    type: DELETE_DOCTOR,
    payload: doctorObject
  };
};


// export const getMoreDOCTORS = () => {
//   return {
//     type: GET_MORE_DOCTORS
//   };
// };
