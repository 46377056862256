import {
  GET_PAYMENTS,
  GET_PAYMENTS_ERROR,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_LOADING,
  GET_PAYMENTS_ROWS_PER_PAGE,
  GET_PAYMENTS_PAGE_NUMBER,
} from "constants/ActionTypes";

export const getPayments = () => {
  return {
    type: GET_PAYMENTS,
  };
};

export const getPaymentsLoading = (value) => {
  return {
    type: GET_PAYMENTS_LOADING,
    payload: value,
  };
};

export const getPaymentsError = (error) => {
  return {
    type: GET_PAYMENTS_ERROR,
    payload: error,
  };
};

export const getPaymentsSuccess = (paymentsList, paymentsMap) => {
  return {
    type: GET_PAYMENTS_SUCCESS,
    payload: {
      paymentsList,
      paymentsMap,
    },
  };
};

export const setRowsPerPage = (value) => {
  return {
    type: GET_PAYMENTS_ROWS_PER_PAGE,
    payload: value,
  };
};

export const setPageNumber = (value) => {
  return {
    type: GET_PAYMENTS_PAGE_NUMBER,
    payload: value,
  };
};
