import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_DOCTORS,
} from "../constants/ActionTypes";
import {
  getDoctorsError,
  getDoctorsSuccess
} from "../actions/Doctors";
import FirestoreService from "../services/firestoreService";

function* getDoctorsHelper() {
  try {
    const result = yield call(FirestoreService.getDoctors);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getDoctorsSuccess(result.data));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getDoctorsError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getDoctorsError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getDoctorsSaga() {
  yield takeEvery(GET_DOCTORS, getDoctorsHelper);
}

export default function* rootSaga() {
  yield all([fork(getDoctorsSaga)]);
}
