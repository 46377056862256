import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Routes = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/users`}
      exact
      component={asyncComponent(() => import("../views/users"))}
    />
    <Route
      path={`${match.url}/doctors`}
      exact
      component={asyncComponent(() => import("../views/doctors"))}
    />
    <Route
      path={`${match.url}/hospitals`}
      exact
      component={asyncComponent(() => import("../views/hospitals"))}
    />
    <Route
      path={`${match.url}/blogs`}
      exact
      component={asyncComponent(() => import("../views/blogs"))}
    />
    <Route
      path={`${match.url}/notifications`}
      exact
      component={asyncComponent(() => import("../views/notifications"))}
    />
    <Route
      path={`${match.url}/profile`}
      exact
      component={asyncComponent(() => import("../views/profile"))}
    />
    <Route
      path={`${match.url}/payments`}
      exact
      component={asyncComponent(() => import("../views/payments"))}
    />
    <Route
      path={`${match.url}/appointments`}
      exact
      component={asyncComponent(() => import("../views/appointments"))}
    />

    {/* Add new data screens */}
    <Route
      path={`${match.url}/addDoctor`}
      exact
      component={asyncComponent(() => import("../views/addDoctor"))}
    />
    <Route
      path={`${match.url}/addHospital`}
      exact
      component={asyncComponent(() => import("../views/addHospital"))}
    />
    <Route
      path={`${match.url}/addBlog`}
      exact
      component={asyncComponent(() => import("../views/addBlog"))}
    />
    <Route
      path={`${match.url}/addIllness`}
      exact
      component={asyncComponent(() => import("../views/addIllness"))}
    />

    {/* Single containers */}
    <Route
      path={`${match.url}/users/:name`}
      component={asyncComponent(() => import("../views/singleUser"))}
    />
    <Route
      path={`${match.url}/doctors/:name`}
      component={asyncComponent(() => import("../views/singleDoctor"))}
    />
    <Route
      path={`${match.url}/hospitals/:name`}
      component={asyncComponent(() => import("../views/singleHospital"))}
    />
    <Route
      path={`${match.url}/blogs/:title`}
      component={asyncComponent(() => import("../views/singleBlog"))}
    />
    <Route
      path={`${match.url}/payments/:uid`}
      component={asyncComponent(() => import("../views/singlePayment"))}
    />
    <Route
      path={`${match.url}/appointments/:uid`}
      component={asyncComponent(() => import("../views/singleAppointment"))}
    />
    {/*<Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>*/}
  </Switch>
);

export default withRouter(Routes);
