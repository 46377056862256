import {
  GET_DOCTORS_LOADING,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_ERROR,
  GET_DOCTORS_ROWS_PER_PAGE,
  GET_DOCTORS_PAGE_NUMBER,
  ADD_DOCTOR,
  DELETE_DOCTOR,
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  doctorsList: [],
  doctorsMap: {},
  singleDoctorId: "",
  singleDoctorMap: {},
  loading: false,
  singleDoctorLoading: false,
  error: "",
  rowsPerPage: 10,
  page: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DOCTORS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
      

    case GET_DOCTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorsList: action.payload.doctorsList,
        doctorsMap: action.payload.doctorsMap
      };
      

    case GET_DOCTORS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      

    case GET_DOCTORS_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload
      };
      

    case GET_DOCTORS_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload
      };
      

    case ADD_DOCTOR:
      return {
        ...state,
        doctorsList: [...state.doctorsList, action.payload]
      };
      

    case DELETE_DOCTOR:
      let tempListDelete = state.doctorsList.filter(elem => {
        return elem.uid !== action.payload.uid;
      });
      return {
        ...state,
        doctorsList: [...tempListDelete]
      };
      

    default:
      return state;
  }
};
