import {
  GET_BLOGS_LOADING,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_ERROR,
  GET_BLOGS_ROWS_PER_PAGE,
  GET_BLOGS_PAGE_NUMBER,
  UPDATE_BLOG,
  DELETE_BLOG,
  ADD_BLOG,
} from "constants/ActionTypes";

const INITIAL_STATE = {
  blogsList: [],
  blogsMap: {},
  loading: false,
  error: "",
  rowsPerPage: 10,
  page: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BLOGS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
      

    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        blogsList: action.payload.blogsList,
        blogsMap: action.payload.blogsMap
      };
      

    case GET_BLOGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      

    case GET_BLOGS_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload
      };
      

    case GET_BLOGS_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload
      };
      

    case UPDATE_BLOG:
      let tempList = state.blogsList.map(elem => {
        return elem.uid === action.payload.uid ? action.payload : elem;
      });
      return {
        ...state,
        blogsList: [...tempList]
      };
      

    case ADD_BLOG:
      return {
        ...state,
        blogsList: [...state.blogsList, action.payload]
      };
      

    case DELETE_BLOG:
      let tempListDelete = state.blogsList.filter(elem => {
        return elem.uid !== action.payload.uid;
      });
      return {
        ...state,
        blogsList: [...tempListDelete]
      };
      

    default:
      return state;
  }
};
