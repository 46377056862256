import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_BLOGS } from "../constants/ActionTypes";
import { getBlogsError, getBlogsSuccess } from "../actions/Blogs";
import FirestoreService from "../services/firestoreService";

function* getBlogsHelper() {
  try {
    const result = yield call(FirestoreService.getBlogs);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getBlogsSuccess(result.data));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getBlogsError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getBlogsError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getBlogsSaga() {
  yield takeEvery(GET_BLOGS, getBlogsHelper);
}

export default function* rootSaga() {
  yield all([fork(getBlogsSaga)]);
}
