import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Users from "./Users";
import Doctors from "./Doctors";
import Hospitals from "./Hospitals";
import Blogs from "./Blogs";
import Notifications from "./Notifications";
import Appointments from "./Appointments";
import Payments from "./Payments";
import Illness from "./Illness";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    users: Users,
    doctors: Doctors,
    hospitals: Hospitals,
    blogs: Blogs,
    notifications: Notifications,
    appointments: Appointments,
    payments: Payments,
    illness: Illness,
  });
