import {
  GET_PAYMENTS_LOADING,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_ERROR,
  GET_PAYMENTS_ROWS_PER_PAGE,
  GET_PAYMENTS_PAGE_NUMBER,
} from "constants/ActionTypes";

const INITIAL_STATE = {
  paymentsList: [],
  paymentsMap: {},
  loading: false,
  singleAppointmentLoading: false,
  error: "",
  rowsPerPage: 10,
  page: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
      

    case GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentsList: action.payload.paymentsList,
        paymentsMap: action.payload.paymentsMap,
      };
      

    case GET_PAYMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      

    case GET_PAYMENTS_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload,
      };
      

    case GET_PAYMENTS_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
      

    default:
      return state;
  }
};
