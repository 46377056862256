import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_USERS,
} from "../constants/ActionTypes";
import {
  getUsersError,
  getUsersSuccess
} from "../actions/Users";
import FirestoreService from "../services/firestoreService";

function* getUsersHelper() {
  try {
    const result = yield call(FirestoreService.getUsers);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getUsersSuccess(result.data));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getUsersError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getUsersError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getUsersSaga() {
  yield takeEvery(GET_USERS, getUsersHelper);
}

export default function* rootSaga() {
  yield all([fork(getUsersSaga)]);
}
