import {
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_ROWS_PER_PAGE,
  GET_USERS_PAGE_NUMBER
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  usersList: [],
  usersMap: {},
  singleUserId: "",
  singleUserMap: {},
  loading: false,
  singleUserLoading: false,
  error: "",
  rowsPerPage: 10,
  page: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
      

    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        usersList: action.payload.usersList,
        usersMap: action.payload.usersMap
      };
      

    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      

    case GET_USERS_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload
      };
      

    case GET_USERS_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload
      };
      

    default:
      return state;
  }
};
