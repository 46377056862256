import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import usersSagas from './Users';
import doctorsSagas from './Doctors';
import hospitalsSagas from './Hospitals';
import notificationSagas from './Notifications';
import blogsSagas from './Blogs';
import appointmentsSagas from "./Appointments";
import paymentsSagas from "./Payments";
import illnessSagas from "./Illness";

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        usersSagas(),
        doctorsSagas(),
        blogsSagas(),
        notificationSagas(),
        hospitalsSagas(),
        paymentsSagas(),
        appointmentsSagas(),
        illnessSagas(),
    ]);
}
