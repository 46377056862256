const WEB_API =
  "https://us-central1-health-consultation.cloudfunctions.net/webApi/api/v1";
export const BROADCAST_TOPIC = "TO_ALL_USERS";
export const BROADCAST_NOTIFICATION_URL =
  WEB_API + "/sendBoradcastNotification";
export const CREATE_DOCTOR_URL = WEB_API + "/createDoctor";
export const DELETE_DOCTOR_URL = WEB_API + "/deleteDoctor";

export const PLACEHOLDER_IMAGE_URL =
  "https://firebasestorage.googleapis.com/v0/b/health-consultation.appspot.com/o/placeholder-img.jpg?alt=media&token=1d8f6c0c-2429-400d-be1a-6c00a55db5a2";

// Initialize Firebase settings
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyB1j0E0HXvlOX8i8x4a9Rgz2Szsnu7zacw",
  authDomain: "health-consultation.firebaseapp.com",
  databaseURL: "https://health-consultation.firebaseio.com",
  projectId: "health-consultation",
  storageBucket: "health-consultation.appspot.com",
  messagingSenderId: "285968471769",
  appId: "1:285968471769:web:694296ece3fe841f5ad0c8",
  measurementId: "G-168D3MMLK4",
};

export const CURRENCY = "USD";